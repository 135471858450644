@tailwind base;
@tailwind components;
@tailwind utilities;

.merriweather-light {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ecfeff;
}

h1,
h2 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}

p {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes wind {
  0% {
    background-position: 0 200px, 0 300px, 100px 250px;
  }
  100% {
    background-position: 1000px 200px, 1200px 300px, 1100px 250px;
  }
}

.leaf {
  width: 100px;
  position: fixed;
}
.leaf:nth-child(1) {
  bottom: 5%;
  left: -10%;
  animation: blowing 12s 1s linear infinite;
}
.leaf:nth-child(2) {
  bottom: 10vh;
  left: -12%;
  animation: blowing 6s linear infinite;
}
.leaf:nth-child(3) {
  bottom: 0vh;
  left: -23%;
  animation: blowing 9s 3s linear infinite;
}
.leaf:nth-child(4) {
  bottom: 30vh;
  left: -19%;
  animation: blowing 7s 3s linear infinite;
}
.leaf:nth-child(5) {
  bottom: 20vh;
  left: -10%;
  animation: blowing 16s 3s linear infinite;
}
.leaf:nth-child(6) {
  bottom: 15vh;
  left: -15%;
  animation: blowing 10s 3s linear infinite;
}
@keyframes blowing {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  20% {
    transform: translate(15vw, -10vh) rotate(170deg);
  }
  65% {
    transform: translate(55vw, -12vh) rotate(550deg);
  }
  80% {
    transform: translate(70vw, -17vh) rotate(740deg);
  }
  100% {
    transform: translate(100vw, -25vh) rotate(920deg);
  }
}
